import React, { useEffect, useState } from "react";
import Content from "../../../../layout/content/Content";
import { useNavigate, useParams } from "react-router-dom";
import { Button } from "reactstrap";
import Cookies from "js-cookie";
import { ShimmerThumbnail } from "react-shimmer-effects";

import { Card } from "reactstrap";
import {
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
} from "../../../../components/Component";
import { useQuery } from "react-query";
import { getPaymentDetails } from "../../../../http/getApi";

const PaymentDetails = ({
  packagePrice,
  retainerPrice,
  addOnTotal,
  travelFee,
  discount1,
  discount2,
  balanceDueAfterRetainer,
  totalFee,
  scheduledPayment2,
  scheduledPayment3,
  scheduledPayment2Date,
  scheduledPayment3Date,
  eventFinancials,
  userId,
  paymentDetails,
  paymentDetailsLoading,
}) => {
  const navigate = useNavigate();
  const { id } = useParams();

  const [retainerPaymentMode, setRetainerPaymentMode] = useState("pending");
  const [scheduledPay2Mode, setScheduledPay2Mode] = useState("pending");
  const [scheduledPay3Mode, setScheduledPay3Mode] = useState("pending");
  const [retainerButtonText, setRetainerButtonText] = useState("Pay");
  const [scheduled2ButtonText, setScheduled2ButtonText] = useState("Pay");
  const [scheduled3ButtonText, setScheduled3ButtonText] = useState("Pay");

  function OpenMakePayment(paymentFor, value, buttonText) {
    if (buttonText === "Pay") {
      navigate(`/my-events/make-payment/${id}`);
      Cookies.set("EventVal", value);
      Cookies.set("paymentType", paymentFor);
      Cookies.set("payerId", userId);
    }
  }

  useEffect(() => {
    if (paymentDetails?.data?.done_payment?.retainer_payment !== undefined) {
      setRetainerPaymentMode("Paypal");
      setRetainerButtonText("Paid");
    }
    if (paymentDetails?.data?.done_payment?.scheduled_payment_2 !== undefined) {
      setScheduledPay2Mode("Paypal");
      setScheduled2ButtonText("Paid");
    }
    if (paymentDetails?.data?.done_payment?.scheduled_payment_3 !== undefined) {
      setScheduledPay3Mode("Paypal");
      setScheduled3ButtonText("Paid");
    }
  }, [paymentDetails?.data?.done_payment]);

  return (
    <React.Fragment>
      <Content>
        <BlockHead size="lg">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle tag="h4">Payment Details</BlockTitle>
              <BlockDes>
                <p>
                  All the details related to the cost of the event and payment
                </p>
              </BlockDes>
            </BlockHeadContent>
            <BlockHeadContent className="align-self-start d-lg-none"></BlockHeadContent>
          </BlockBetween>
        </BlockHead>

        {!eventFinancials ? (
          <>
            <ShimmerThumbnail height={250} width={1200} rounded />
            <ShimmerThumbnail height={250} width={1200} rounded />
          </>
        ) : (
          <div>
            <Block>
              {paymentDetailsLoading ? (
                <ShimmerThumbnail height={250} width={500} rounded />
              ) : (
                <div className="nk-data data-list">
                  <div className="data-head">
                    <h6 className="overline-title"> Payment History </h6>
                  </div>
                  <div className="my-3">
                    <table className="table table-bordered mb-0">
                      <thead>
                        <tr>
                          <th className="text-center" scope="col">
                            Payment Type
                          </th>
                          <th className="text-center" scope="col">
                            Date
                          </th>
                          <th className="text-center" scope="col">
                            Payment Mode
                          </th>
                          <th className="text-center" scope="col">
                            Amount
                          </th>
                          <th className="text-center" scope="col">
                            Make payment
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr className="fw-normal">
                          <td className="text-center">Retainer</td>
                          <td className="text-center">-------</td>
                          <td className="text-center">{retainerPaymentMode}</td>
                          <td className="text-center">${retainerPrice}</td>
                          <td className="text-center">
                            <button
                              color="primary"
                              className="btn btn-primary btn-lg mt-2"
                              onClick={() =>
                                OpenMakePayment(
                                  1,
                                  retainerPrice,
                                  retainerButtonText
                                )
                              }
                            >
                              {retainerButtonText}
                            </button>
                          </td>
                        </tr>
                        {scheduledPayment2 && (
                          <tr className="fw-normal">
                            <td className="text-center">Scheduled Payment 2</td>
                            <td className="text-center">
                              {scheduledPayment2Date}
                            </td>
                            <td className="text-center">{scheduledPay2Mode}</td>
                            <td className="text-center">
                              ${scheduledPayment2}
                            </td>
                            <td className="text-center">
                              <button
                                disabled={retainerPaymentMode === "pending"}
                                color="primary"
                                className="btn btn-primary btn-lg mt-2"
                                onClick={() =>
                                  OpenMakePayment(
                                    2,
                                    scheduledPayment2,
                                    scheduled2ButtonText
                                  )
                                }
                              >
                                {scheduled2ButtonText}
                              </button>
                            </td>
                          </tr>
                        )}

                        {scheduledPayment3 && (
                          <tr className="fw-normal">
                            <td className="text-center">Scheduled Payment 3</td>
                            <td className="text-center">
                              {scheduledPayment3Date}
                            </td>
                            <td className="text-center">{scheduledPay3Mode}</td>
                            <td className="text-center">
                              ${scheduledPayment3}
                            </td>
                            <td className="text-center">
                              <button
                                disabled={
                                  retainerPaymentMode === "pending" ||
                                  scheduledPay2Mode === "pending"
                                }
                                className="btn btn-primary btn-lg mt-2"
                                onClick={() =>
                                  OpenMakePayment(
                                    3,
                                    scheduledPayment3,
                                    scheduled3ButtonText
                                  )
                                }
                              >
                                {scheduled3ButtonText}
                              </button>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              )}

              <div className="nk-data data-list">
                <div className="data-head">
                  <h6 className="overline-title">Invoice</h6>
                </div>
                <div className="my-3">
                  <table className="table table-bordered ">
                    <thead>
                      <tr>
                        <th scope="col">Item</th>
                        <th scope="col">Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className="fw-light">
                        <td>Demo Package</td>
                        <td>${packagePrice}</td>
                      </tr>
                      {addOnTotal && (
                        <tr className="fw-light">
                          <td>Addon Total</td>
                          <td>${addOnTotal}</td>
                        </tr>
                      )}
                      <tr>
                        <td>Total</td>
                        <td>${totalFee}</td>
                      </tr>
                      {discount1 !== 0 && (
                        <tr>
                          <td> Discount 1</td>
                          <td>${travelFee}</td>
                        </tr>
                      )}
                      {discount2 !== 0 && (
                        <tr>
                          <td>Discount 2</td>
                          <td>${travelFee}</td>
                        </tr>
                      )}
                      {travelFee !== 0 && (
                        <tr>
                          <td>Travel Fee</td>
                          <td>${travelFee}</td>
                        </tr>
                      )}
                      <tr className="fw-light">
                        <td>Retainer</td>
                        <td>${retainerPrice}</td>
                      </tr>
                      <tr className="fw-light">
                        <td>Balance due After Deposit</td>
                        <td>${balanceDueAfterRetainer}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </Block>
          </div>
        )}

        <Button
          className="px-3 my-2"
          color="primary"
          size="sm"
          onClick={() => navigate("/my-events")}
        >
          <Icon className="ni-arrow-long-left"></Icon>
          <span> Back</span>
        </Button>
      </Content>
    </React.Fragment>
  );
};

export default PaymentDetails;
