import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import Appbar from "./appbar/Appbar";
import Head from "./head/Head";
import AppRoot from "./global/AppRoot";
import AppMain from "./global/AppMain";
import AppWrap from "./global/AppWrap";
import "../css/style.css";
import AppHeader from "./header/AppHeader";
import Cookies from "js-cookie";

const Layout = ({ title, app, ...props }) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    // let at = Cookies.get("authToken");
    // if (!at) {
    //   window.location = "/";
    // }

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <>
      <Head title={!title && "Loading"} />
      <AppRoot>
        {/* <Appbar /> */}
        <AppMain>
          {/* <Sidebar fixed /> */}
          <AppWrap>
            <section
              style={{
                padding: `${windowWidth >= 568 ? "0% 10%" : "0px"}`,
                paddingTop: "20px",
              }}
            >
              <AppHeader app={app} fixed />
              <Outlet />
            </section>
          </AppWrap>
        </AppMain>
      </AppRoot>
    </>
  );
};
export default Layout;
