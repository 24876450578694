import React from "react";
// import { useState } from "react";
import { Label, Row, Col, Button, Form, FormGroup, Input } from "reactstrap";
import Cards from "react-credit-cards-2";
import "react-credit-cards-2/dist/es/styles-compiled.css";
import { Icon } from "../../../../components/Component";
import Swal from "sweetalert2";
// import { loadStripe } from "@stripe/stripe-js";
// import { Elements } from "@stripe/react-stripe-js";
import { useFormik } from "formik";

const AddCard = ({ setNewCard }) => {
  // const [state, setState] = useState({
  //   number: "",
  //   expiry: "",
  //   cvc: "",
  //   name: "",
  //   focus: "",
  // });

  const formik = useFormik({
    initialValues: {
      number: "",
      expiry: "",
      cvc: "",
      name: "",
      focus: "",
    },
    // validationSchema: schema,
    onSubmit: (value) => {
      console.log(value);
    },
  });

  console.log(formik.values);

  const handleInputChange = (evt) => {
    const { name, value } = evt.target;
    const maxLength = evt.target.maxLength;
    const truncatedValue = maxLength ? value.slice(0, maxLength) : value;

    // setState((prev) => ({ ...prev, [name]: truncatedValue }));
    formik.setFieldValue("name", truncatedValue);
    formik.setFieldValue("number", truncatedValue);
    formik.setFieldValue("cvc", truncatedValue);
    formik.setFieldValue("expiry", truncatedValue);
  };

  const handleInputFocus = (e) => {
    // setState((prev) => ({ ...prev, focus: evt.target.name }));
    formik.setFieldValue("focus", e.name);
  };

  function handleSubmit(e) {
    e.preventDefault();
    setNewCard(false);
    Swal.fire({
      icon: "success",
      title: "Done",
      text: "Card Added Succesfully",
      focusConfirm: false,
    });
  }

  return (
    <>
      <Row>
        <Col
          sm="6"
          lg="6"
          className="d-flex align-items-center justify-content-center"
        >
          <Cards
            number={formik.values.number}
            expiry={formik.values.expiry}
            cvc={formik.values.cvc}
            name={formik.values.name}
            focused={formik.values.focus}
          />
        </Col>
        <Col sm="6" lg="6">
          <Form>
            <FormGroup>
              <Label for="number">Card Number</Label>
              <Input
                type="number"
                name="number"
                id="number"
                maxLength={16}
                placeholder="Card Number"
                value={formik.values.number}
                onChange={formik.handleChange}
                onFocus={handleInputFocus}
              />
            </FormGroup>
            <FormGroup>
              <Label for="name">Cardholder Name</Label>
              <Input
                type="text"
                name="name"
                id="name"
                placeholder="Cardholder Name"
                maxLength={18}
                value={formik.values.name}
                onChange={formik.handleChange}
                onFocus={handleInputFocus}
              />
            </FormGroup>
            <FormGroup>
              <Label for="expiry">Expiry Date</Label>
              <Input
                type="number"
                name="expiry"
                id="expiry"
                placeholder="MM/YY"
                maxLength={4} // Set maxLength directly
                value={formik.values.expiry}
                onChange={formik.handleChange}
                onFocus={handleInputFocus}
              />
            </FormGroup>
            <FormGroup>
              <Label for="cvc">CVC</Label>
              <Input
                type="number"
                name="cvc"
                id="cvc"
                placeholder="CVC"
                maxLength={3} // Set maxLength directly
                value={formik.values.cvc}
                onChange={formik.handleChange}
                onFocus={handleInputFocus}
              />
            </FormGroup>
            <div className="d-flex justify-content-between">
              <Button color="primary" onClick={() => setNewCard(false)}>
                <Icon className="ni-arrow-long-left"></Icon>
                <span> Back</span>
              </Button>
              <Button color="primary" type="submit" onClick={handleSubmit}>
                <em className="icon ni ni-plus"></em>
                <span> Add</span>
              </Button>
            </div>
          </Form>
        </Col>
      </Row>
    </>
  );
};

export default AddCard;
