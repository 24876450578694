import React, { useState } from "react";
import AddCard from "./AddCard";
import SavedCards from "./SavedCards";

const CardPay = () => {
  const [newCard, setNewCard] = useState(false);

  return (
    <>
      <div>
        {newCard === true ? (
          <AddCard setNewCard={setNewCard} />
        ) : (
          <SavedCards setNewCard={setNewCard} />
        )}
      </div>
    </>
  );
};

export default CardPay;
