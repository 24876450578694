import { useFormik } from "formik";
import { useEffect, useState } from "react";
import ReactSelect from "react-select";
import { Col, Input, Label, Row } from "reactstrap";
import { Icon } from "../../components/Component";
import NavigationFooter from "../components/NavigationFooter";
import * as Yup from "yup";
import UploadImage from "../components/UploadImage";
import { useMutation, useQuery } from "react-query";
import { getClientsList } from "../../http/getApi";
import { useParams } from "react-router";
import { editClientDetails } from "../../http/editApi";
import { ShimmerThumbnail } from "react-shimmer-effects";

const Profile = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { id } = useParams();

  const stateOptions = [
    { value: "ny", label: "New York" },
    { value: "ca", label: "California" },
    { value: "tx", label: "Texas" },
    { value: "fl", label: "Florida" },
    { value: "il", label: "Illinois" },
    { value: "pa", label: "Pennsylvania" },
    { value: "oh", label: "Ohio" },
    { value: "ga", label: "Georgia" },
  ];

  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  function togglePasswordVisibility() {
    setIsPasswordVisible(!isPasswordVisible);
  }

  const schema = Yup.object().shape({
    first_name: Yup.string().required("First name is required."),
    last_name: Yup.string().required("Last name is required."),
    work_phone_number: Yup.string()
      .nullable()
      .matches(
        /^[0-9]{9,13}$/,
        "Work mobile no must be between 9 to 13 digits."
      ),
    phone_number: Yup.string()
      .nullable()
      .matches(
        /^[0-9]{9,13}$/,
        "Home phone no must be between 9 to 13 digits."
      ),
    zipcode: Yup.string()
      .nullable()
      .matches(/^[a-zA-Z0-9]{5,10}$/, "Zip-code must be at least 5 digits."),
  });

  let initialValues = {
    username: "",
    first_name: "",
    last_name: "",
    organization_name: "",
    work_phone_number: "",
    phone_number: "",
    email: "",
    password: "",
    address_1: "",
    address_2: "",
    city: "",
    state: "",
    files: "",
    zipcode: "",
    website: "",
  };

  const {
    data: clientsList,
    isLoading: clientsListIsLoading,
    isError: clientsListIsError,
  } = useQuery({
    queryKey: ["get-clients-by-id"],
    queryFn: () => getClientsList(),
  });

  console.log(clientsList);

  //Mutation hook for editing client
  const {
    mutate: editClientMutation,
    isLoading: isClientEditing,
    error: clientEditError,
  } = useMutation({
    mutationKey: ["Edit-client-by-ID"],
    mutationFn: (value) => editClientDetails(value),
  });
  const editClientFromList = (value) => {
    try {
      editClientMutation(value);
    } catch (error) {
      console.error("Error editing client:", error);
    }
  };

  useEffect(() => {
    if (!clientsListIsLoading) {
      formik.setValues((prevData) => {
        return {
          ...prevData,
          ...clientsList?.data?.clients[0],
          country: parseInt(clientsList?.data?.clients[0]?.country),
          state: parseInt(clientsList?.data?.clients[0]?.state),
          files: clientsList?.data?.clients[0]?.profile_image_path,
        };
      });
    }
  }, [clientsListIsLoading]);

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: schema,
    onSubmit: (value) => {
      console.log("lol");
      editClientFromList(value);
    },
  });

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        {clientsListIsLoading ? (
          <Row>
            <Col md={6} className="border p-3 bg-white">
              <Row>
                <Col md={6} className="border p-3 bg-white">
                  <ShimmerThumbnail height={250} width={300} rounded />;
                  <ShimmerThumbnail height={40} width={300} rounded />
                  <ShimmerThumbnail height={40} width={300} rounded />
                  <ShimmerThumbnail height={40} width={300} rounded />
                </Col>
                <Col md={6} className="border p-3 bg-white">
                  <ShimmerThumbnail height={50} width={300} rounded />
                  <ShimmerThumbnail height={50} width={300} rounded />
                  <ShimmerThumbnail height={50} width={300} rounded />
                  <ShimmerThumbnail height={50} width={300} rounded />
                  <ShimmerThumbnail height={50} width={300} rounded />
                  <ShimmerThumbnail height={50} width={300} rounded />
                </Col>
              </Row>
            </Col>
            <Col md={6} className="border p-3 bg-white">
              <Row>
                <ShimmerThumbnail height={40} width={600} rounded />
                <ShimmerThumbnail height={40} width={600} rounded />
                <ShimmerThumbnail height={40} width={600} rounded />
                <ShimmerThumbnail height={40} width={600} rounded />
                <Row className="mt-5">
                  <Col md={4}>
                    <ShimmerThumbnail height={40} width={200} rounded />
                  </Col>
                  <Col md={4}>
                    <ShimmerThumbnail height={40} width={200} rounded />
                  </Col>
                  <Col md={4}>
                    <ShimmerThumbnail height={40} width={200} rounded />
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <ShimmerThumbnail height={40} width={300} rounded />
                  </Col>
                  <Col md={6}>
                    <ShimmerThumbnail height={40} width={300} rounded />
                  </Col>
                </Row>
              </Row>
            </Col>
          </Row>
        ) : (
          <Row className="p-2 mt-2 ">
            <Col md={6} className="border p-3 bg-white">
              <Row>
                <Col md={6}>
                  <UploadImage
                    label="Upload profile picture"
                    name={"files"}
                    formik={formik}
                  />
                </Col>
                <Col md={6}>
                  <div>
                    <Label for="firstName">First Name</Label>
                    <Input
                      type="text"
                      id="first_name"
                      name="first_name"
                      value={formik.values.first_name}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      placeholder="Enter first name"
                    />
                    {formik.errors.first_name && formik.touched.first_name && (
                      <p style={{ color: "red" }}>
                        {" "}
                        {formik.errors.first_name}
                      </p>
                    )}
                  </div>

                  <div className="mt-4">
                    <Label htmlFor="lastName">Last Name</Label>
                    <Input
                      type="text"
                      id="last_name"
                      value={formik.values.last_name}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      name="last_name"
                      placeholder="Enter last name"
                    />
                    {formik.errors.last_name && formik.touched.last_name && (
                      <p style={{ color: "red" }}> {formik.errors.last_name}</p>
                    )}
                  </div>

                  <div className="mt-4">
                    <Label for="organization">Organization Name</Label>
                    <Input
                      type="text"
                      id="organization_name"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.organization_name}
                      name="organization_name"
                      placeholder="Enter organization name"
                    />
                    {formik.errors.organization_name &&
                      formik.touched.organization_name && (
                        <p style={{ color: "red" }}>
                          {formik.errors.organization_name}
                        </p>
                      )}
                  </div>
                </Col>
              </Row>

              {/* Second row  */}
              <Row className="mt-4">
                <Col md={6}>
                  <div>
                    <Label for="email">Email</Label>
                    <Input
                      id="email"
                      disabled
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      type="email"
                      placeholder="Enter email"
                      name="email"
                    />
                    {formik.errors.email && formik.touched.email && (
                      <p style={{ color: "red" }}> {formik.errors.email}</p>
                    )}
                  </div>
                </Col>
                <Col md={6}>
                  <div>
                    <Label for="website">Website</Label>
                    <Input
                      id="website"
                      type="website"
                      placeholder="Enter website"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      name="website"
                    />
                  </div>
                </Col>
              </Row>

              <h4 className="my-4">Login Details</h4>
              {/* Second row  */}
              <Row>
                <Col md={6}>
                  <div className="form-control-wrap">
                    <Label for="username">Username</Label>
                    <div className="form-icon form-icon-right mt-4">
                      <Icon name={"icon ni ni-user"} className="icon mt-2" />
                    </div>
                    <Input
                      id="username"
                      disabled
                      value={formik.values.username}
                      type="username"
                      placeholder="Enter username"
                      name="username"
                    />
                  </div>
                </Col>
                <Col md={6}>
                  <div className="form-control-wrap">
                    <Label
                      for="pass"
                      className="d-flex justify-content-between align-items-center"
                    >
                      <span>Password</span>
                    </Label>
                    <div className="form-icon form-icon-right mt-4">
                      <Icon
                        name={isPasswordVisible ? "ni ni-eye" : "ni ni-eye-off"}
                        className="icon mt-2"
                        onClick={togglePasswordVisibility}
                        style={{ cursor: "pointer" }}
                      />
                    </div>
                    <Input
                      type={isPasswordVisible ? "text" : "Password"}
                      id="password"
                      name="password"
                      className="form-control"
                      value={"John123@"}
                      placeholder={
                        isPasswordVisible ? "Enter password" : "● ● ● ● ● ● ●"
                      }
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                  </div>
                </Col>
              </Row>
            </Col>
            <Col md={6} className="border p-3 bg-white border-start-0">
              {/* third row */}
              <h4 className="p-0 mb-2">Telephone Numbers</h4>
              <Row>
                <Col>
                  <div>
                    <Label for="email">Home Phone</Label>
                    <Input
                      id="phone_number"
                      type="number"
                      onKeyDown={(evt) =>
                        ["e", "E", "+", "-"].includes(evt.key) &&
                        evt.preventDefault()
                      }
                      onChange={formik.handleChange}
                      value={formik.values.phone_number}
                      onBlur={formik.handleBlur}
                      placeholder="Enter Home Phone"
                      name="phone_number"
                    />
                    {formik.errors.phone_number &&
                      formik.touched.phone_number && (
                        <p style={{ color: "red" }}>
                          {formik.errors.phone_number}
                        </p>
                      )}
                  </div>
                </Col>
              </Row>
              {/* forth row  */}
              <Row className="mt-4">
                <Col>
                  <div>
                    <Label for="email">Work Phone</Label>
                    <Input
                      id="work_phone_number"
                      type="number"
                      placeholder="Enter Work Phone"
                      value={formik.values.work_phone_number}
                      onKeyDown={(evt) =>
                        ["e", "E", "+", "-"].includes(evt.key) &&
                        evt.preventDefault()
                      }
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      name="work_phone_number"
                    />
                    {formik.errors.work_phone_number &&
                      formik.touched.work_phone_number && (
                        <p style={{ color: "red" }}>
                          {formik.errors.work_phone_number}
                        </p>
                      )}
                  </div>
                </Col>
              </Row>
              {/* fifth row */}
              <h4 className="my-4">Mailing Address</h4>
              <Row>
                <Col md={4}>
                  <div>
                    <Label for="address1">Address Line1</Label>
                    <Input
                      id="address_1"
                      value={formik.values.address_1}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      placeholder="Enter address line1"
                      name="address_1"
                    />
                  </div>
                </Col>
                <Col md={4}>
                  <div>
                    <Label for="address2">Address Line2</Label>
                    <Input
                      id="address_2"
                      value={formik.values.address_2}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      placeholder="Enter address line2"
                      name="address_2"
                    />
                  </div>
                </Col>
                <Col md={4}>
                  <div>
                    <Label for="city">City Name</Label>
                    <Input
                      id="city"
                      placeholder="Enter city name"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      name="city"
                    />
                  </div>
                </Col>
              </Row>
              {/* sixth row  */}
              <Row className="mt-4">
                <Col md={6}>
                  <div>
                    <Label for="state">Select State</Label>
                    <ReactSelect
                      options={stateOptions}
                      id="state"
                      name="state"
                      defaultValue={stateOptions.find(
                        (option) => option.label === "California"
                      )}
                    />
                  </div>
                </Col>
                <Col md={6}>
                  <div>
                    <Label for="zipcode">Zip-code</Label>
                    <Input
                      id="zipcode"
                      type="number"
                      placeholder="Enter zip code"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      onKeyDown={(evt) =>
                        ["e", "E", "+", "-"].includes(evt.key) &&
                        evt.preventDefault()
                      }
                      name="zipcode"
                    />
                    {formik.errors.zipcode && formik.touched.zipcode && (
                      <p style={{ color: "red" }}> {formik.errors.zipcode}</p>
                    )}
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        )}

        <NavigationFooter lastPage={"1"} currentState={"1"} formik={formik} />
      </form>
    </>
  );
};
export default Profile;
