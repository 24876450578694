import React, { useState } from "react";
import { Button, Input } from "reactstrap";
import { Icon } from "../../../../components/Component";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

const SavedCards = ({ setNewCard }) => {
  const navigate = useNavigate();
  const [selectedCard, setSelectedCard] = useState(null);
  const [selectedButton, setSelecetedButton] = useState(null);

  const [existingCards, setExistingCards] = useState([
    {
      cardHolderName: "Sourabh Patil",
      type: "visa",
      last4Digits: "1234",
      expiryDate: "10/25",
    },
    {
      cardHolderName: "John Doe",
      type: "mastercard",
      last4Digits: "5678",
      expiryDate: "14/26",
    },
    {
      cardHolderName: "Elena Cruz",
      type: "visa",
      last4Digits: "2335",
      expiryDate: "24/27",
    },
  ]);

  function selectCard(index) {
    setSelectedCard(index);
    setSelecetedButton(index);
  }

  const handlePayment = () => {
    if (selectedCard !== null) {
      console.log("Payment initiated with:", existingCards[selectedCard]);
      Swal.fire({
        icon: "success",
        title: "Successful",
        text: "Payment done Succesfully",
        focusConfirm: false,
      });
    } else {
      toast.error("Please select a card");
    }
  };

  const handleClick = () => setNewCard(true);

  function deleteCard(index) {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        setExistingCards(existingCards.filter((_, i) => i !== index));
        Swal.fire("Deleted!", "Your card has been deleted.", "success");
      }
    });
  }

  return (
    <div className="container mt-4">
      <h5>Select a saved card</h5>
      <div
        className={
          existingCards.length > 3
            ? "card-list-container overflow-auto border"
            : "border px-3 my-4"
        }
        style={{ maxHeight: "300px" }}
      >
        {existingCards.map((card, index) => (
          <div
            key={index + 1}
            className="d-flex mb-3 align-items-center my-3 border border-1 rounded p-3"
          >
            <div className="cardTypeIcon">
              {card.type === "visa" ? (
                <Icon className="ni-visa-alt h2 w2 "></Icon>
              ) : card.type === "mastercard" ? (
                <Icon className="ni-master-card h2 w2 "></Icon>
              ) : null}
            </div>
            <div className="cardholderName mx-auto">
              <Input
                id={`cardHolderName_${index}`}
                value={card.cardHolderName}
                disabled
              />
            </div>
            <div className="cardNumber mx-auto ">
              <Input
                id={`cardNumber_${index}`}
                value={"**** **** ****" + card.last4Digits}
                disabled
              />
            </div>
            <div className="expiryDate mx-auto w-100px">
              <Input
                id={`expiryDate_${index}`}
                value={card.expiryDate}
                className="text-center"
                disabled
              />
            </div>
            <div className="select mx-auto">
              <Button
                color="primary"
                className={
                  selectedButton === index
                    ? ""
                    : "btn-dim justify-content-center"
                }
                onClick={() => selectCard(index)}
                outline={selectedButton === index ? false : true}
                style={{ width: "125px" }}
              >
                <Icon
                  className={
                    selectedButton === index
                      ? "ni-check-circle-fill"
                      : "ni-chevrons-up"
                  }
                ></Icon>
                <span>{selectedButton === index ? "Selected" : "Select"}</span>
              </Button>
            </div>
            <div className="delete">
              <Button
                color="danger"
                className="btn-dim"
                onClick={() => deleteCard(index)}
              >
                <Icon className="ni-trash"></Icon>
                <span>Delete</span>
              </Button>
            </div>
          </div>
        ))}
      </div>
      <div className="d-flex justify-content-between">
        <Button
          className="px-3"
          color="primary"
          size="sm"
          onClick={() => navigate("/my-events")}
        >
          <Icon className="ni-arrow-long-left"></Icon>
          <span> Back</span>
        </Button>

        <div>
          <Button color="primary" className="me-3" onClick={handlePayment}>
            <em class="icon ni ni-sign-dollar"></em>
            <span>Pay</span>
          </Button>
          <Button color="primary" onClick={handleClick}>
            <em class="icon ni ni-plus"></em>
            <span> Add New Card</span>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default SavedCards;
