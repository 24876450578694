import React from "react";
import {
  Row,
  Col,
  Button,
  Card,
  CardBody,
  CardHeader,
  Label,
} from "reactstrap";
import { Icon } from "../../../../components/Component";
import { useNavigate, useParams } from "react-router-dom";
import logo from "../../../../assets/images/jsTree/Logo.png";
import { usePDF } from "react-to-pdf";
import { formatDate, formatTime } from "../../../../utils/Utils";

const Agreement = ({
  clientName,
  email,
  eventName,
  packageName,
  eventType,
  address,
  zipCode,
  eventdate,
  contactNumber,
  retainerPrice,
  scheduledPayment2,
  scheduledPayment3,
  scheduledPayment2Date,
  scheduledPayment3Date,
  retainerTxnId,
  schedulePay2TxnId,
  schedulePay3TxnId,
  retainerPaidDate,
  scheduled2PaidDate,
  scheduled3PaidDate,
}) => {
  const { toPDF, targetRef } = usePDF({ filename: "agreement.pdf" });
  const navigate = useNavigate();
  const { id } = useParams();

  return (
    <div className="Agreement border mt-5 p-4 bg-white">
      <Row className="mb-4">
        <Col xs="12" md="6">
          <h4 className="mb-0">Iparty e-Signature Agreement</h4>
        </Col>
        <Col xs="12" md="6" className="text-md-end mt-2 mt-md-0">
          <Button
            className="px-3"
            color="primary"
            size="sm"
            onClick={() => toPDF()}
          >
            <Icon className="ni-printer"></Icon>
            <span> Print</span>
          </Button>
        </Col>
      </Row>

      <div ref={targetRef}>
        <Row className="border py-3 mx-0 ">
          <Col xs="12" lg="4">
            <div className="fw-bold d-flex align-items-center p-3 border rounded">
              <Icon className="ni-lock px-2 fs-5"></Icon>
              <p className="mb-0 fs-5 px-2">Digitally Signed</p>
            </div>
          </Col>
          <Col xs="12" lg="4" className="mt-3 mt-lg-0 ">
            <div>
              <p className="mb-0 ">
                Signed By : <strong>{clientName}</strong>
              </p>
              <p className="mb-0">
                Email : <strong>{email}</strong>
              </p>
            </div>
          </Col>
          <Col xs="12" lg="4" className="mt-3 mt-lg-0">
            <div>
              <p className="mb-0">
                Event Date :<strong>{eventdate}</strong>
              </p>
              <p className="mb-0">
                Contact :{contactNumber && <strong>{contactNumber}</strong>}
              </p>
            </div>
          </Col>
        </Row>

        {/* Logo and Contract details */}
        <Row className="border bg-white py-3 mx-0">
          <Col
            xs="12"
            lg="6"
            className="d-flex justify-content-center justify-content-lg-start"
          >
            <img
              src={logo}
              alt="Logo"
              style={{ width: "150px", height: "150px" }}
            />
          </Col>
          <Col
            xs="12"
            lg="6"
            className="bg-white d-flex flex-column justify-content-center"
          >
            <div className="text-center text-lg-start">
              <h5 className="mb-3">Contract</h5>
              <p className="mb-2">
                Contract number: <strong>23450</strong>
              </p>
              <p className="mb-2">
                Account number: <strong>132456</strong>
              </p>
            </div>
          </Col>
        </Row>

        {/* Client Information */}
        <Row className="border bg-white py-3 mx-0">
          <Col xs="12" lg="4">
            <h5 className="mb-3">Client Information</h5>
            <p className="mb-2">
              Name: <strong>{clientName}</strong>
            </p>
            <p className="mb-2">
              Email : <strong>{email}</strong>
            </p>
            <p className="mb-2">
              Contact: <strong>{contactNumber}</strong>
            </p>
          </Col>
          <Col xs="12" lg="4">
            <h5 className="mb-3">Event Information</h5>
            <p className="mb-2">
              Event Name: <strong>{eventName}</strong>
            </p>
            <p className="mb-2">
              Package : <strong>{packageName}</strong>
            </p>
            <p className="mb-2">
              Date : <strong>{eventdate}</strong>
            </p>
          </Col>
          <Col xs="12" lg="4">
            <h5 className="mb-3">Location Information </h5>
            <p className="mb-2">
              Address :<strong>{address}</strong>
            </p>
            <p className="mb-2">
              Zip : <strong>{zipCode}</strong>
            </p>
          </Col>
        </Row>

        <Row className="border bg-white py-3 mx-0">
          <Col>
            <Card className=" border mb-3">
              <CardHeader className="  fw-bold">Charges</CardHeader>
              <CardBody>
                <div className="my-3">
                  <table className="table table-bordered mb-0">
                    <thead>
                      <tr>
                        <th className="text-center" scope="col">
                          Payment Type
                        </th>
                        <th className="text-center" scope="col">
                          Date
                        </th>
                        <th className="text-center" scope="col">
                          Amount
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {retainerTxnId && (
                        <tr className="fw-normal">
                          <td className="text-center">Retainer</td>
                          <td className="text-center">{retainerPaidDate}</td>
                          <td className="text-center">${retainerPrice}</td>
                        </tr>
                      )}

                      {schedulePay2TxnId && (
                        <tr className="fw-normal">
                          <td className="text-center">Scheduled Payment 2</td>
                          <td className="text-center">{scheduled2PaidDate}</td>
                          <td className="text-center">${scheduledPayment2}</td>
                        </tr>
                      )}

                      {schedulePay3TxnId && (
                        <tr className="fw-normal">
                          <td className="text-center">Scheduled Payment 3</td>
                          <td className="text-center">{scheduled3PaidDate}</td>
                          <td className="text-center">${scheduledPayment3}</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>

        {/* Tearms and conditions */}
        <Row className="border bg-white py-3 mx-0">
          <Card className="card">
            <CardHeader className="border-bottom fw-bold text-center ">
              Terms and Conditions
            </CardHeader>
            <CardBody className="card-body">
              <div>
                <table className="table table-bordered mb-0">
                  <tbody>
                    <tr>
                      <td className="bg-white">
                        <ul className="list-group list-group-flush list-group-numbered bg-white">
                          <li className="list-group-item bg-transparent">
                            Payment Terms: A 50% deposit is required upon
                            booking, with the remaining balance due one week
                            before the event. Payment methods accepted include
                            cash, check, or online transfer.{" "}
                          </li>
                          <li className="list-group-item bg-transparent">
                            Cancellation Policy: Cancellations made more than 30
                            days before the event will receive a full refund.
                            Cancellations within 30 days will forfeit the
                            deposit, with considerations for rescheduling due to
                            unforeseen circumstances.
                          </li>
                          <li className="list-group-item bg-transparent">
                            Equipment Usage: Clients are responsible for any
                            damage to equipment during the event. Our team
                            handles setup and operational aspects, with
                            additional equipment requests requiring two weeks'
                            notice.
                          </li>
                          <li className="list-group-item bg-transparent">
                            Performance Duration: Our standard performance
                            duration is four hours, with the option for
                            additional hours at an extra charge. Overtime fees
                            apply for exceeding the agreed-upon duration.
                          </li>
                          <li className="list-group-item bg-transparent">
                            Venue Requirements: Venues must provide adequate
                            power outlets and ensure compliance with local noise
                            regulations. Advance arrangements for setup and
                            breakdown access are necessary.
                          </li>
                          <li className="list-group-item bg-transparent">
                            Music Selection: Clients are encouraged to provide a
                            playlist of preferred songs and genres. Guest song
                            requests are accommodated, with the right to exclude
                            inappropriate music.
                          </li>
                          <li className="list-group-item bg-transparent">
                            Liability: Our company holds liability insurance to
                            cover unforeseen accidents during the event. Clients
                            are responsible for obtaining necessary
                            permits/licenses and acknowledge non-liability for
                            guest misconduct.
                          </li>
                          <li className="list-group-item bg-transparent">
                            Weather Contingency: Clients must have an alternate
                            indoor location arranged for inclement weather.
                            Outdoor events may be rescheduled or canceled due to
                            severe weather conditions, necessitating a backup
                            plan.
                          </li>
                          <li className="list-group-item bg-transparent">
                            Communication: Clients can contact our team via
                            phone or email for inquiries or assistance. A
                            dedicated point of contact will be provided for
                            coordinating event logistics.
                          </li>
                          <li className="list-group-item bg-transparent">
                            Agreement Acceptance: Booking our services implies
                            agreement with the outlined terms and conditions.
                            Any modifications to the agreement must be made in
                            writing and acknowledged by both parties.
                            Non-compliance may result in termination without
                            refund.
                          </li>
                        </ul>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </CardBody>
          </Card>
        </Row>
      </div>
      <div>
        <Button
          className="px-3 my-2"
          color="primary"
          size="sm"
          onClick={() => navigate("/my-events")}
        >
          <Icon className="ni-arrow-long-left"></Icon>
          <span> Back</span>
        </Button>
      </div>
    </div>
  );
};

export default Agreement;
