import React, { useState, useEffect } from 'react'
import './countdown.css'
import Fireworks from './fireworks/Fireworks'

const currentDate = new Date()
const futureDate = new Date(currentDate.getTime() + 10000)
// const futureDate = new Date(currentDate.getTime() + 100000000)
const COUNTDOWN_TARGET = futureDate

const getTimeLeft = () => {
  const totalTimeLeft = COUNTDOWN_TARGET - new Date()
  const days = Math.floor(totalTimeLeft / (1000 * 60 * 60 * 24))
  const hours = Math.floor((totalTimeLeft / (1000 * 60 * 60)) % 24)
  const minutes = Math.floor((totalTimeLeft / (1000 * 60)) % 60)
  const seconds = Math.floor((totalTimeLeft / 1000) % 60)
  return { days, hours, minutes, seconds }
}

const Countdown = () => {
  const [timeLeft, setTimeLeft] = useState(() => getTimeLeft())

  const [showCounter, setShowCounter] = useState(true)

  // Use Effect for Countdown
  useEffect(() => {
    const timer = setInterval(() => {
      const { days, hours, minutes, seconds } = getTimeLeft()
      if (days === 0 && hours === 0 && minutes === 0 && seconds === 0) {
        setShowCounter(false) // hides the counter  component
      }
      setTimeLeft(getTimeLeft())
    }, 1000)

    return () => {
      clearInterval(timer)
    }
  }, [])

  return (
    <div className='d-flex justify-content-center align-items-center'>
      <div
        className="countdown my-4 border bg-white mx-4"
        style={{ width: '85%' }}
      >
        <h4 className="mt-4 ms-2">Countdown To Your Next Event</h4>
        <div className="content mt-4 my-2">
          {showCounter ? (
            Object.entries(timeLeft).map((el) => {
              const label = el[0]
              const value = el[1]
              return (
                <div className="box" key={label}>
                  <div className="value">
                    <span>{value}</span>
                  </div>
                  <span className="label"> {label} </span>
                </div>
              )
            })
          ) : (
            <>
              <h1>Event is live now : )</h1>
              <Fireworks />
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default Countdown
