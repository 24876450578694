import React, { useState, useEffect } from "react";
import Content from "../../../../layout/content/Content";
import { Card } from "reactstrap";
import { useNavigate } from "react-router-dom";

import {
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  Button,
} from "../../../../components/Component";
import { formatDate } from "../../../../utils/Utils";
import { toast } from "react-toastify";

const TransactionDetails = ({
  retainerPrice,
  scheduledPayment2,
  scheduledPayment3,
  retainerTxnId,
  schedulePay2TxnId,
  schedulePay3TxnId,
  paymentDetails,
  clientName,
  eventdate,
  retainerPaidDate,
  scheduled2PaidDate,
  scheduled3PaidDate,
}) => {
  const navigate = useNavigate();

  function copyTxnId(elementId) {
    const txnId = document.getElementById(elementId).textContent;

    const tempInput = document.createElement("input");
    tempInput.value = txnId;

    document.body.appendChild(tempInput);

    tempInput.select();
    tempInput.setSelectionRange(0, 99999);

    document.execCommand("copy");
    document.body.removeChild(tempInput);
    toast.success("Transaction ID copied to clipboard: " + txnId);
  }

  return (
    <React.Fragment>
      <Content>
        <BlockHead size="lg">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle tag="h4">Transfer Details </BlockTitle>
              <BlockDes>
                <p>All the details about your transaction </p>
              </BlockDes>
            </BlockHeadContent>
            <BlockHeadContent className="align-self-start d-lg-none"></BlockHeadContent>
          </BlockBetween>
        </BlockHead>

        <Block>
          {retainerTxnId ? (
            <div className="nk-data data-list">
              <div className="mb-2">
                <table className="table table-bordered mb-0  table-responsive">
                  <thead>
                    <tr>
                      <th scope="col">Payment type</th>
                      <th scope="col"> Amount</th>
                      <th scope="col">Transaction Id</th>
                      <th scope="col"> Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {retainerTxnId && (
                      <tr className="fw-normal">
                        <td>Retainer</td>
                        <td>${retainerPrice}</td>
                        <td className="d-flex justify-content-between">
                          <span id="retainerTxnId">{retainerTxnId}</span>
                          <button onClick={() => copyTxnId("retainerTxnId")}>
                            <em class="icon ni ni-copy"></em>
                          </button>
                        </td>
                        <td>{retainerPaidDate}</td>
                      </tr>
                    )}
                    {schedulePay2TxnId && (
                      <tr className="fw-normal">
                        <td>scheduled Payment 2 </td>
                        <td>${scheduledPayment2}</td>
                        <td className="d-flex justify-content-between">
                          <span id="schedulePay2TxnId">
                            {schedulePay2TxnId}
                          </span>
                          <button
                            onClick={() => copyTxnId("schedulePay2TxnId")}
                          >
                            <em class="icon ni ni-copy"></em>
                          </button>
                        </td>
                        <td>{scheduled2PaidDate}</td>
                      </tr>
                    )}
                    {schedulePay3TxnId && (
                      <tr className="fw-normal">
                        <td> scheduled Payment 3</td>
                        <td>${scheduledPayment3}</td>
                        <td className="d-flex justify-content-between btn btn-link">
                          <span id="schedulePay3TxnId">
                            {schedulePay3TxnId}
                          </span>
                          <button
                            onClick={() => copyTxnId("schedulePay3TxnId")}
                          >
                            <em class="icon ni ni-copy"></em>{" "}
                          </button>
                        </td>
                        <td>{scheduled3PaidDate}</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          ) : (
            <div className="alert alert-warning" role="alert">
              <p className="mb-0">
                <strong>Hey {clientName},</strong> you haven't made any payments
                yet. Make sure you complete all payments before the event date (
                {eventdate}).
              </p>
            </div>
          )}
        </Block>
        <Button
          className="px-3 my-2"
          color="primary"
          size="sm"
          onClick={() => navigate("/my-events")}
        >
          <Icon className="ni-arrow-long-left"></Icon>
          <span> Back</span>
        </Button>
      </Content>
    </React.Fragment>
  );
};

export default TransactionDetails;
