import React from "react";
import { Col, Row, Label, Input, Button } from "reactstrap";
import Contactus from "../../assets/images/Contactus.svg";
import Required from "../../components/Required";
import ReCAPTCHA from "react-google-recaptcha";
import { useRef, useState } from "react";

const Contact = () => {
  const recaptcha = useRef();
  const [recaptchaToken, setRecaptchaToken] = useState(null);

  const handleRecaptchaChange = (token) => {
    setRecaptchaToken(token);
    console.log("captcha token :", token);
  };

  function handleSubmit(event) {
    event.preventDefault();
    const captchaValue = recaptcha.current.getValue();
    if (!captchaValue) {
      alert("Please verify the reCAPTCHA!");
    } else {
      alert("Form submission successful!");
    }
  }

  return (
    <div className="contact mt-2 bg-white border" style={{ height: "100%" }}>
      <Row className="h-100">
        <Col
          xs="12"
          md="6"
          className="d-flex align-items-center justify-content-center border-end"
        >
          <img src={Contactus} alt="Contact us" />
        </Col>

        {/* Contact us Form */}
        <Col
          xs="12"
          md="6"
          className="d-flex  align-items-center justify-content-center"
        >
          <div className="p-4">
            <h3 className="mb-3 mt-0">Contact us</h3>
            <form onSubmit={handleSubmit}>
              <Row className="mb-3">
                <Col>
                  <Label for="subject">
                    Subject
                    <Required />
                  </Label>
                  <Input type="text" placeholder="Subject" required />
                </Col>
              </Row>
              <Row className="mb-3">
                <Col>
                  <Label for="notes4">
                    Message <Required />
                  </Label>
                  <textarea
                    className="form-control form-control-sm"
                    placeholder="Write your notes"
                    required
                  />
                </Col>
              </Row>
              <div className="md:flex md:items-center mx-auto ">
                <ReCAPTCHA
                  ref={recaptcha}
                  sitekey={
                    process.env.REACT_APP_CAPTCHA_GOOGLE_CAPTCHA_SITE_KEY
                  }
                  onChange={handleRecaptchaChange}
                />
                <Button color="primary" type="submit">
                  Submit
                </Button>
              </div>
            </form>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Contact;
