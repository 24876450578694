import React, { useState } from "react";
import { Button, Dropdown, DropdownMenu, DropdownToggle } from "reactstrap";
import { Link } from "react-router-dom";
import { Icon } from "../../components/Component";
import { useThemeUpdate } from "../provider/Theme";
import Cookies from "js-cookie";
// import User from "../user/User";

const AppDropdown = () => {
  const themeUpdate = useThemeUpdate();
  const [open, setOpen] = useState(false);
  const toggle = () => {
    themeUpdate.sidebarHide();
    setOpen(!open);
  };

  let currentUrl;

  if (window.location.pathname !== undefined) {
    currentUrl = window.location.pathname;
  } else {
    currentUrl = null;
  }
  const setLogout = () => {
    const now = new Date();
    const expires = new Date(now.getTime() + 24 * 60 * 60 * 1000);

    Cookies.set("logoutURL", process.env.REACT_APP_ACCOUNT_URL, {
      domain: process.env.REACT_APP_COOKIE_DOMAIN,
      expires: expires,
    });
    Cookies.set("dashboard", "client", {
      domain: process.env.REACT_APP_COOKIE_DOMAIN,
      expires: expires,
    });
    const logoutUrl = `${process.env.REACT_APP_ACCOUNT_URL}logout`;
    window.location.href = logoutUrl;
  };
  return (
    <Dropdown isOpen={open} toggle={toggle}>
      <DropdownToggle
        tag="a"
        href="#dropdown"
        onClick={(ev) => ev.preventDefault()}
        className="dropdown-toggle nk-quick-nav-icon"
      >
        <div className="icon-status icon-status-na">
          <Icon name="menu-circled"></Icon>
        </div>
      </DropdownToggle>
      <DropdownMenu end className="dropdown-menu-lg">
        <div className="dropdown-body">
          <ul className="list-apps">
            <li>
              <Link
                to={`${process.env.PUBLIC_URL}/`}
                onClick={toggle}
                className="nav-link"
              >
                <span className="list-apps-media">
                  <Icon name="home"></Icon>
                </span>
                <span
                  className={
                    currentUrl === "/home" || currentUrl === "/"
                      ? "text-primary text-dark list-apps-title"
                      : "text-dark list-apps-title"
                  }
                >
                  Home
                </span>
              </Link>
            </li>
            <li>
              <Link
                to="my-events"
                onClick={toggle}
                className={
                  currentUrl === "/my-events"
                    ? "text-primary nav-link text-dark"
                    : "nav-link text-dark"
                }
              >
                <span className="list-apps-media">
                  <Icon name="info-i"></Icon>
                </span>
                <span
                  className={
                    currentUrl === "/my-events"
                      ? "text-primary text-dark list-apps-title"
                      : "text-dark list-apps-title"
                  }
                >
                  Events
                </span>
              </Link>
            </li>
            <li>
              <Link
                to="profile"
                onClick={toggle}
                className={
                  currentUrl === "/profile"
                    ? "text-primary nav-link text-dark"
                    : "nav-link text-dark"
                }
              >
                <span className="list-apps-media">
                  <Icon name="play"></Icon>
                </span>
                <span
                  className={
                    currentUrl === "/profile"
                      ? "text-primary text-dark list-apps-title"
                      : "text-dark list-apps-title"
                  }
                >
                  Profile
                </span>
              </Link>
            </li>
            <li>
              <Link
                to="contact-us"
                onClick={toggle}
                className={
                  currentUrl === "/contact-us"
                    ? "text-primary nav-link text-dark"
                    : "nav-link text-dark"
                }
              >
                <span className="list-apps-media">
                  <Icon name="contact"></Icon>
                </span>
                <span
                  className={
                    currentUrl === "/contact-us"
                      ? "text-primary text-dark list-apps-title"
                      : "text-dark list-apps-title"
                  }
                >
                  Contact
                </span>
              </Link>
            </li>
            <Button
              color="light"
              type="button"
              className="rounded-3 d-none d-md-block"
              onClick={setLogout}
            >
              <Icon name="icon ni ni-signout" />
              <span>Logout</span>
            </Button>
          </ul>
        </div>
      </DropdownMenu>
    </Dropdown>
  );
};

export default AppDropdown;
