export const sendSMS = (contact_no, message) => {
  const accountSid = "AC4d535992a84547ff6f456da1f37ef3a0";
  const authToken = "421ff24e6796ee4442ece03d077bd72f";
  const twilioNumber = "+16204136307";
  fetch(
    "https://api.twilio.com/2010-04-01/Accounts/" + accountSid + "/Messages",
    {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: "Basic " + btoa(accountSid + ":" + authToken),
      },
      body: new URLSearchParams({
        To: contact_no,
        From: twilioNumber,
        Body: message,
      }),
    }
  )
    .then((response) => response.json())
    .then((data) => console.log(data))
    .catch((error) => console.error(error));
};
