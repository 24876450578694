import { Button } from "reactstrap";

const NavigationFooter = ({ toggle, currentState, lastPage, formik, validationFunction }) => {
  const next = parseInt(currentState) + 1;
  const back = parseInt(currentState) - 1;
  const submitFormHandler = () => {
    formik.handleSubmit();
  };
  const handelNext = () => {
    // validationFunction((validation) => {
    //   if (validation === true) {
    //     toggle(next.toString());
    //   }
    // });
  };

  return (
    <>
      <div
        className={`d-flex ${
          currentState === "1" ? "justify-content-end" : "justify-content-between"
        } align-items-center mt-3`}
      >
        {currentState !== "1" && (
          <Button color="primary" className="btn-dim" outline type="button" onClick={() => toggle(back.toString())}>
            <em class="icon ni ni-arrow-long-left"></em>
            <span>Previous</span>
          </Button>
        )}
        {currentState !== lastPage ? (
          <>
            <Button color="primary" type="button" onClick={handelNext}>
              <span>Next</span>
              <em class="icon ni ni-arrow-long-right"></em>
            </Button>
          </>
        ) : (
          <>
            <Button color="primary" type="button" onClick={submitFormHandler}>
              <span>Save</span>
              <em class="icon ni ni-save"></em>
            </Button>
          </>
        )}
      </div>
    </>
  );
};
export default NavigationFooter;
