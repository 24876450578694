import Slider from "react-slick";
import {
  SlickArrowLeft,
  SlickArrowRight,
} from "../../../components/partials/slick/SlickComponents";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import EventCard from "../../home/components/EventCard";
import { getUpcomingEventsList } from "../../../http/getApi";
import { Button } from "reactstrap";
import { useNavigate } from "react-router";
import { ShimmerThumbnail } from "react-shimmer-effects";
import Cookies from "js-cookie";

const UpcomingEvents = () => {
  const navigate = useNavigate();
  const userName = Cookies.get("username");

  const [upcomingEvents, setUpcomingEvents] = useState([]);

  function OpenPlanEvent() {
    navigate("/my-events/plan-event");
  }

  const settings = {
    className: "slider-init plan-list",
    // slidesToShow: 3,
    centerMode: false,
    slidesToScroll: 1,
    infinite: false,
    responsive: [
      { breakpoint: 1539, settings: { slidesToShow: 1 } },
      { breakpoint: 992, settings: { slidesToShow: 1 } },
      { breakpoint: 768, settings: { slidesToShow: 1 } },
    ],
    slide: "li",
    prevArrow: <SlickArrowLeft />,
    nextArrow: <SlickArrowRight />,
    dots: true,
  };

  //fetching event data
  const {
    data: upcomingEventsList,
    isLoading: upcomingEventsLoading,
    isError: upcomingEventsListError,
  } = useQuery({
    queryKey: ["get-upcoming-events-list"],
    queryFn: () =>
      getUpcomingEventsList({
        id: "",
        page: "",
        limit: "",
        search: "",
      }),
  });

  useEffect(() => {
    if (upcomingEventsList?.data?.length > 0) {
      setUpcomingEvents(upcomingEventsList?.data);
    }
  }, [upcomingEventsList]);

  return (
    <section className="p-3">
      {upcomingEventsLoading ? (
        <div className="text-center">
          <ShimmerThumbnail height={250} rounded className="bg-drak" />
        </div>
      ) : upcomingEvents && upcomingEvents.length > 0 ? (
        <div>
          <h4 className="mx-2 my-2">Your Upcoming Events</h4>
          <div className="d-flex" style={{ flexWrap: "wrap" }}></div>
          <div>
            <Slider {...settings}>
              {upcomingEvents.map((event, index) => (
                <EventCard
                  key={index}
                  eventType={event.event_type_name}
                  eventName={event.event_name}
                  eventDate={event.start_date_time}
                  Venue={event?.venue_details?.[0]?.city}
                  id={event?.id}
                />
              ))}
            </Slider>
          </div>
        </div>
      ) : (
        <div className="text-center">
          <h3 className="mb-4">No Upcoming Events Scheduled</h3>
          <p className="lead mb-4">
            Hey ,{userName ? userName : "Sourabh"}, it looks like there are no
            upcoming events scheduled at the moment.
          </p>
          <p className="mb-4">
            Don't worry, we're here to help! You can start planning your next
            event with us.
          </p>
          <Button color="primary">Plan Your Event</Button>
        </div>
      )}
    </section>
  );
};
export default UpcomingEvents;
